.block-margin {
  margin: 3rem 0;
}

.unit-margin {
  margin: 1rem 0;
}

.unit-margin-uniform {
  margin: 1rem;
}

.unit-margin-sides {
  margin: 0 1rem;
}

.header__logo {
  text-align: center;
  padding-top: 10px;
}

.upload-label {
  background: #1890ff;
  padding: 10px 10px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
}

.upload-input {
  display: inline;
  float: right;
}
